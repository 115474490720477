import { createReducer, createActions } from 'reduxsauce';
import _, { find, indexOf, remove } from 'lodash';
import { getSessionFields } from 'modules/sessions/utils/get-session-fields';
import { setDefaultSessionQueryData } from 'modules/sessions/hooks/useSessionByIdQuery';
import { queryClient } from 'config/react-query';

export const INITIAL_STATE = {};

export const { Types: UserTypes, Creators: UserActions } = createActions(
  {
    loginUser: ['data'],
    logoutUser: null,
    setSpyMode: ['spyMode'],
    changeActiveSession: ['activeSession'],
    notificationsUser: ['notifications'],
    updateLicenses: ['licenses'],
    updateAvatar: ['avatar'],
    addRoundTestTipShows: ['roundTestTipShows'],
    addGroupLowMarketingBudgetNotification: [
      'groupLowMarketingBudgetNotification',
    ],
    addSessionCreating: ['sessionId'],
    removeSessionCreating: ['sessionId'],
    initProductCreating: ['data'],
    finishProductCreating: ['data'],
  },
  {},
);

const loginUser = ( state, { data } ) => ( { ...state, ...data } );
const logoutUser = () => {
  queryClient.clear();
  return {};
};
const changeActiveSession = ( state, { activeSession } ) => {
  if ( activeSession && activeSession.id ) {
    setDefaultSessionQueryData( activeSession.id, activeSession );
  }
  return {
    ...state,
    activeSession: activeSession
      ? { ...activeSession, ...getSessionFields( activeSession ) }
      : activeSession,
  };
};
const setSpyMode = ( state, { spyMode } ) => ( { ...state, spyMode } );
const notificationsUser = ( state, { notifications } ) => ( {
  ...state,
  notifications,
} );
const updateLicenses = ( state, { licenses } ) => ( { ...state, licenses } );
const updateAvatar = ( state, { avatar } ) => ( { ...state, avatar } );
const addRoundTestTipShows = ( state, { roundTestTipShows } ) => {
  if ( state.roundTestTipShows ) {
    const actually = _.find( state.roundTestTipShows, { id: roundTestTipShows } );
    if ( actually ) _.remove( state.roundTestTipShows, actually );

    state.roundTestTipShows.push( { id: roundTestTipShows, date: Date.now() } );
    return { ...state };
  }
  return {
    ...state,
    roundTestTipShows: [{ id: roundTestTipShows, date: Date.now() }],
  };
};
const addGroupLowMarketingBudgetNotification = (
  state,
  { groupLowMarketingBudgetNotification },
) => {
  if ( state.groupLowMarketingBudgetNotification ) {
    // eslint-disable-next-line max-len
    const actually = _.find( state.groupLowMarketingBudgetNotification, { id: groupLowMarketingBudgetNotification } );
    if ( actually ) _.remove( state.groupLowMarketingBudgetNotification, actually );

    state.groupLowMarketingBudgetNotification.push( {
      id: groupLowMarketingBudgetNotification,
      date: Date.now(),
    } );
    return { ...state };
  }
  return {
    ...state,
    groupLowMarketingBudgetNotification: [
      { id: groupLowMarketingBudgetNotification, date: Date.now() },
    ],
  };
};
const initProductCreating = ( state, { data } ) => {
  const { activeSession: { id: sessionId } } = state;
  const product = { ...data };
  delete product.description;
  const productInfo = {
    product,
    sessionId,
    isDone: false,
    time: new Date().getTime(),
  };
  if ( !state.productsCreating ) {
    return { ...state, productsCreating: [productInfo] };
  }
  const productsCreating = [...state.productsCreating];
  const foundProduct = find( productsCreating, { product, sessionId } );

  if ( foundProduct ) {
    foundProduct.isDone = false;
    foundProduct.time = new Date().getTime();
  } else {
    productsCreating.push( productInfo );
  }

  return { ...state, productsCreating };
};
const finishProductCreating = ( state, { data } ) => {
  const { activeSession: { id: sessionId } } = state;
  const product = { ...data };
  delete product.description;
  if ( !state.productsCreating ) {
    return { ...state };
  }
  const productsCreating = [...state.productsCreating];
  const foundProduct = find( productsCreating, { product, sessionId } );
  const index = indexOf( productsCreating, foundProduct );

  if ( index !== -1 ) {
    delete productsCreating[index];
  }

  return { ...state, productsCreating };
};
const addSessionCreating = ( state, { sessionId } ) => {
  if ( !state.sessionsCreating ) {
    return { ...state, sessionsCreating: [sessionId] };
  }
  const sessionsCreating = [...state.sessionsCreating];
  if ( indexOf( sessionsCreating, sessionId ) === -1 ) {
    sessionsCreating.push( sessionId );
  }

  return { ...state, sessionsCreating };
};
const removeSessionCreating = ( state, { sessionId } ) => {
  if ( !state.sessionsCreating ) {
    return { ...state };
  }
  const sessionsCreating = [...state.sessionsCreating];
  remove( sessionsCreating, session => session === sessionId );

  return { ...state, sessionsCreating };
};

export const reducer = createReducer( INITIAL_STATE, {
  [UserTypes.LOGIN_USER]: loginUser,
  [UserTypes.LOGOUT_USER]: logoutUser,
  [UserTypes.CHANGE_ACTIVE_SESSION]: changeActiveSession,
  [UserTypes.SET_SPY_MODE]: setSpyMode,
  [UserTypes.NOTIFICATIONS_USER]: notificationsUser,
  [UserTypes.UPDATE_LICENSES]: updateLicenses,
  [UserTypes.UPDATE_LICENSES]: updateLicenses,
  [UserTypes.UPDATE_AVATAR]: updateAvatar,
  [UserTypes.ADD_ROUND_TEST_TIP_SHOWS]: addRoundTestTipShows,
  [UserTypes.ADD_GROUP_LOW_MARKETING_BUDGET_NOTIFICATION]: addGroupLowMarketingBudgetNotification,
  [UserTypes.INIT_PRODUCT_CREATING]: initProductCreating,
  [UserTypes.FINISH_PRODUCT_CREATING]: finishProductCreating,
  [UserTypes.ADD_SESSION_CREATING]: addSessionCreating,
  [UserTypes.REMOVE_SESSION_CREATING]: removeSessionCreating,
} );

export default UserActions;
