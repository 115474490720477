import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Error404 from 'components/theme/Error404';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import UserActions from 'store/reducers/User';
import { z } from 'zod';
import General from './General';
import Message from './Message';
import Information from './Information';
import Activities from './Activities';
import AdsPage from './General/Ads';
import ReportAiPage from './ReportAIPage';

const Session = ( { match } ) => {
  // Verify if uuid is valid
  const uuid = match.params.id;
  const { success } = z
    .string()
    .uuid()
    .safeParse( uuid );
  if ( !success ) {
    return <Error404 />;
  }

  return (
    <div className="d-flex flex-column flex-grow-1 h-100 pt-2">
      <Switch>
        <Route path={`${match.url}/info`} component={Information} />
        {/* <Route path={`${match.url}/dashboard`} component={Dashboard} /> */}
        <Route path={`${match.url}/activities`} component={Activities} />
        <Route
          path={`${match.url}/general`}
          render={props => <General {...props} sessionId={uuid} />}
        />
        <Route
          path={`${match.url}/messages`}
          render={props => <Message {...props} />}
        />
        <Route
          path={`${match.url}/ads`}
          render={props => <AdsPage {...props} sessionId={uuid} />}
        />
        <Route
          path={`${match.url}/ai-report`}
          render={() => <ReportAiPage sessionId={uuid} />}
          exact
        />

        <Route component={Error404} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( withRouter( Session ) );
